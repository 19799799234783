export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "ArchiveIcon",
  },
  {
    title: "Settings",
    icon: "SettingsIcon",
    children: [
      {
        title: "Division",
        route: "division",
      },
      {
        title: "District",
        route: "district",
      },
      {
        title: "Upazila",
        route: "upazila",
      },
    ],
  },
  {
    title: "Candidate",
    icon: "UsersIcon",
    children: [
      {
        title: "Link Type",
        route: "link-type",
      },
      {
        title: "Skills Manage",
        route: "skills",
      },
      {
        title: "Education Level",
        route: "education-level",
      },
      {
        title: "Candidate List",
        route: "candidates",
      },
      {
        title: "Industry",
        route: "industry",
      },
    ],
  },
  {
    title: "Company Jobs",
    icon: "UsersIcon",
    children: [
      {
        title: "Create Job",
        route: "create-job",
      },
      {
        title: "View Jobs",
        route: "job-list",
      },
    ],
  },
  {
    title: "Company",
    icon: "UsersIcon",
    children: [
      {
        title: "Company",
        route: "company",
      },
    ],
  },
];
